import React from "react"
import styles from "./blockTwoColumns.module.css"

const BlockTwoColumns = ({ bgColor, fontColor, data }) => {
  const {
    titleOne,
    textOne: { processed: textOneProcessed },
    titleTwo,
    textTwo: { processed: textTwoProcessed },
  } = data
  return (
    <section className={`${styles.blockTwoColumns} ${bgColor} ${fontColor}`}>
      <div className={styles.blockTwoColumns__item}>
        <h2>{titleOne}</h2>
        <div
          className={styles.blockTwoColumns__item__text}
          dangerouslySetInnerHTML={{ __html: textOneProcessed }}
        />
      </div>
      <div className={styles.blockTwoColumns__item}>
        <h2>{titleTwo}</h2>
        <div
          className={styles.blockTwoColumns__item__text}
          dangerouslySetInnerHTML={{ __html: textTwoProcessed }}
        />
      </div>
    </section>
  )
}

export default BlockTwoColumns
