import React from "react"
import { Link } from "gatsby"
import "./masonry.module.css"

const items = [
  {
    id: "brick1",
    img:
      "https://static01.nyt.com/images/2014/02/14/blogs/20140214-lens-worldpress-slide-HYMZ/20140214-lens-worldpress-slide-HYMZ-superJumbo.jpg",
  },
  {
    id: "brick2",
    img:
      "https://static01.nyt.com/images/2014/02/14/blogs/20140214-lens-worldpress-slide-HYMZ/20140214-lens-worldpress-slide-HYMZ-superJumbo.jpg",
  },
  {
    id: "brick3",
    img:
      "https://static01.nyt.com/images/2014/02/14/blogs/20140214-lens-worldpress-slide-HYMZ/20140214-lens-worldpress-slide-HYMZ-superJumbo.jpg",
  },
  {
    id: "brick4",
    img:
      "https://static01.nyt.com/images/2014/02/14/blogs/20140214-lens-worldpress-slide-HYMZ/20140214-lens-worldpress-slide-HYMZ-superJumbo.jpg",
  },
]

let lastOpenedElem = null

const restartElems = () => {
  getAllBricks().map(removeBlur)
  getAllBricks().map(removeBrickContent)
}
const shrinkSiblings = openedElem => {
  getAllBricks()
    .filter(sibling => sibling !== openedElem)
    .map(setWidthTo1)
}
const grow = openedElem => (openedElem.style.width = "97%")

const toggleBlur = openedElem =>
  openedElem.querySelector("img").classList.toggle("blur")

const showBrickContent = openedElem => {
  const elem = openedElem.querySelector(".masonry__brick__content")
  elem.style.display = "block"
  setTimeout(() => {
    elem.style.opacity = 1
  }, 500)
}

const hideBrickContent = openedElem => {
  const elem = openedElem.querySelector(".masonry__brick__content")
  elem.style.opacity = 0
  setTimeout(() => (elem.style.display = "none"), 500)
}

const restoreSiblingsSize = () => {
  let increment = 10
  const bricks = getAllBricks().map(elem => {
    restoreInitialWidth(elem, increment)
    increment += 10
  })
}

const getAllBricks = () =>
  Array.from(
    document.getElementById("masonry").querySelectorAll(".masonry__brick")
  )

const setWidthTo1 = elem => (elem.style.width = "1%")

const restoreInitialWidth = (elem, increment) =>
  (elem.style.width = increment + "%")

const removeBlur = elem => elem.querySelector("img").classList.remove("blur")

const removeBrickContent = elem =>
  (elem.querySelector(".masonry__brick__content").style.display = "none")

function toggleBrick(e) {
  const openedElem = e.target.closest(".masonry__brick")
  if (lastOpenedElem !== openedElem) {
    restartElems()
    shrinkSiblings(openedElem)
    grow(openedElem)
    toggleBlur(openedElem)
    showBrickContent(openedElem)
    lastOpenedElem = openedElem
  } else {
    hideBrickContent(openedElem)
    restoreSiblingsSize()
    toggleBlur(openedElem)
    lastOpenedElem = null
  }
}

const MasonryBrick = ({ data }) => {
  const { id, img, text, path } = data
  return (
    <div id={id} className="masonry__brick">
      <div className="masonry__brick__overlay" onClick={e => toggleBrick(e)}>
        <img src={`https://drupal.smartempo.com${img}`} />
      </div>
      <div className="masonry__brick__content">
        <p>{text}</p>
        <Link to={path}>
          <button>READ MORE</button>
        </Link>
      </div>
    </div>
  )
}

const Masonry = ({ bricks }) => {
  return (
    <section id="masonry" className="masonry">
      {bricks.map(brickData => (
        <MasonryBrick key={`mi-${brickData.id}`} data={brickData} />
      ))}
    </section>
  )
}

export default Masonry
