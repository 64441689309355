import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import Masonry from "../components/Masonry/Masonry"
import BlockBand from "../components/BlockBand/BlockBand"
import BlockTextBig from "../components/BlockTextBig/BlockTextBig"
import BlockFullWidthImg from "../components/BlockFullWidthImg/BlockFullWidthImg"
import BlockImageMargin from "../components/BlockImageMargin/BlockImageMargin"
import BlockVideoMargin from "../components/BlockVideoMargin/BlockVideoMargin"
import BlockLeftTitleText from "../components/BlockLeftTitleText/BlockLeftTitleText"
import BlockTextImageLeft from "../components/BlockTextImageLeft/BlockTextImageLeft"
import BlockTextImageRight from "../components/BlockTextImageRight/BlockTextImageRight"
import BlockLeftTitleTextSmall from "../components/BlockLeftTitleTextSmall"
import BlockTwoColumns from "../components/BlockTwoColumns/BlockTwoColumns"
import BlockContact from "../components/BlockContact/BlockContact"
import Footer from "../components/Footer/Footer"

import { useHomePage } from "../hooks/use-homePage"

const Layout = () => {
  const homeData = useHomePage()
  const leftTitleTextFirstData = {
    title: homeData.field_lefttitletext_first_title_,
    text: homeData.field_lefttitletext_first_text_,
  }
  const leftTitleTextSecondData = {
    title: homeData.field_lefttitletext_second_title,
    text: homeData.field_lefttitletext_second_text_,
  }
  const leftTitleTextThirdData = {
    title: homeData.field_lefttitletext_third_title_,
    text: homeData.field_lefttitletext_third_text_,
  }
  const twoColumnsData = {
    titleOne: homeData.field_two_columns_col_1_title_,
    textOne: homeData.field_two_columns_col_1_text_,
    titleTwo: homeData.field_two_columns_col_2_title_,
    textTwo: homeData.field_two_columns_col_2_text_,
  }
  const textImageRight = {
    title: homeData.field_text_image_right_title_,
    text: homeData.field_text_image_right_text_,
    relationships: homeData.relationships,
  }
  const textImageLeft = {
    title: homeData.field_text_image_left_title_,
    text: homeData.field_text_image_left_text_,
    relationships: homeData.relationships,
  }
  const fullWidthImgData = {
    img: homeData.relationships.field_full_width_img,
  }
  const masonryData = [
    {
      id: "brick1",
      img: homeData.relationships.field_masonry_image_1.uri.url,
      text: homeData.field_masonry_box_1_text_,
      path: homeData.field_masonry_box_1_path_,
    },
    {
      id: "brick2",
      img: homeData.relationships.field_masonry_image_2.uri.url,
      text: homeData.field_masonry_box_2_text_,
      path: homeData.field_masonry_box_2_path_,
    },
    {
      id: "brick3",
      img: homeData.relationships.field_masonry_image_3.uri.url,
      text: homeData.field_masonry_box_3_text_,
      path: homeData.field_masonry_box_3_path_,
    },
    {
      id: "brick4",
      img: homeData.relationships.field_masonry_image_4.uri.url,
      text: homeData.field_masonry_box_4_text_,
      path: homeData.field_masonry_box_4_path_,
    },
  ]

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <Masonry bricks={masonryData} />
        {<BlockBand bgColor="bg-red" />}
        <BlockTextBig
          bgColor="bg-purple"
          fontColor="font-white"
          text={homeData.field_textbig_text_.processed}
        />
        {<BlockVideoMargin bgColor="bg-purple" data={homeData} /> }
        {/* {<BlockFullWidthImg img={fullWidthImgData} />} */}
        <BlockLeftTitleText
          bgColor="bg-red"
          fontColor="font-white"
          data={leftTitleTextFirstData}
        />
        <BlockLeftTitleTextSmall bgColor="bg-red-light" data={homeData} />
        <BlockLeftTitleText
          bgColor="bg-grey"
          fontColor="font-grey"
          data={leftTitleTextSecondData}
        />
        <BlockTwoColumns data={twoColumnsData} />
        <BlockTextImageRight bgColor="bg-yellow" data={textImageRight} />
        <BlockTextImageLeft bgColor="bg-yellow-light" data={textImageLeft} />
        {/* <BlockLeftTitleText
          bgColor="bg-blue"
          fontColor="font-white"
          data={leftTitleTextThirdData}
        /> */}
        <BlockContact bgColor="bg-purple" />
        <Footer />
      </div>
    </div>
  )
}

export default Layout
