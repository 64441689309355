import React from "react"
import BlockContactForm from "../BlockContactForm/BlockContactForm"

const BlockContact = ({ bgColor }) => {
  return (
    <section className={`contact ${bgColor}`}>
      <BlockContactForm />
      <div className="contact__text">
        <h1>Drop us a line.</h1>
        <p>
          Include your information and a bit about the services you're most
          interested in or the challenges you're facing, and we'll be in touch
          soon.
        </p>
      </div>
    </section>
  )
}

export default BlockContact
