import React from "react"

const BlockLeftTitleText = ({ bgColor, data }) => {
  const {
    field_lefttitletextsmall_title_: title,
    field_lefttitletextsmall_text_: { processed: text },
  } = data
  return (
    <section className={`blockLeftTitleTextSmall ${bgColor}`}>
      <div className="blockLeftTitleTextSmall__content">
        <h1>{title}</h1>
        <div
          className="blockLeftTitleTextSmall__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </section>
  )
}

export default BlockLeftTitleText
