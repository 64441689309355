import React from "react"
//import { Link } from "gatsby"

const BlockBand = ({ bgColor, text }) => {
  return (
    <section
      className={`blockBand ${bgColor}`}
      // dangerouslySetInnerHTML={{ __html: text }}
    >
      Congratulations to our client{" "}
      <a
        href="https://www.orlandohealth.com"
        target="_blank"
        style={{ fontWeight: "bold", color: "#ffffff" }}
      >
        Orlando Health
      </a>{" "}
      for being recognized as a finalist for the{" "}
      <a
        href="https://www.workfront.com/blog/2020-lion-award-finalists-north-america"
        target="_blank"
        style={{ fontWeight: "bold", color: "#ffffff" }}
      >
        Workfront Lion Award
      </a>{" "}
      for Strategic Achievement&nbsp;
    </section>
  )
}
export default BlockBand
