import React from "react"
import styles from "./blockLeftTitleText.module.css"

const BlockLeftTitleText = ({ bgColor, fontColor, data }) => {
  const { title, text } = data
  return (
    <section className={`${styles.blockLeftTitleText} ${fontColor} ${bgColor}`}>
      <div className={styles.blockLeftTitleText__content}>
        <h1>{title}</h1>
        <div
          className={styles.blockLeftTitleText__text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </section>
  )
}

export default BlockLeftTitleText
