import React from "react"
import styles from "./blockTextImageLeft.module.css"

const BlockTextImageLeft = ({ bgColor, data }) => {
  const {
    title,
    text,
    relationships: {
      field_text_image_left_img_: {
        uri: { url: image },
      },
    },
  } = data
  return (
    <section className={`${styles.blockTextImageLeft} ${bgColor}`}>
      <div className={styles.blockTextImageLeft__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={title} />
      </div>
      <div className={styles.blockTextImageLeft__text}>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </section>
  )
}

export default BlockTextImageLeft
