import React from "react"
import styles from "./blockFullWidthImg.module.css"

const BlockFullWidthImg = ({ img }) => {
  const {
    img: {
      uri: { url: image },
    },
  } = img
  return (
    <section className={styles.blockFullWidthImg}>
      {/* <img src={`https://drupal.smartempo.com${image}`} /> */}
    </section>
  )
}

export default BlockFullWidthImg
