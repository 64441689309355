import React from "react"
import styles from "./blockTextBig.module.css"

const BlockTextBig = ({ bgColor, fontColor, text }) => {
  return (
    <section
      className={`${styles.blockTextBig} ${fontColor} ${bgColor}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

export default BlockTextBig
