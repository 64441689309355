import React from "react"
import styles from "./blockTextImageRight.module.css"

const BlockTextImageRight = ({ bgColor, fontColor, data }) => {
  const {
    title,
    text,
    relationships: {
      field_text_image_right_img_: {
        uri: { url: image },
      },
    },
  } = data
  return (
    <section className={`${styles.blockTextImageRight} ${bgColor}`}>
      <div className={styles.blockTextImageRight__text}>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <div className={styles.blockTextImageRight__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt="Image" />
      </div>
    </section>
  )
}

export default BlockTextImageRight
