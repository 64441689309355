import React from "react"
import styles from "./blockImageMargin.module.css"

const BlockImageMargin = ({ bgColor, data }) => {
  const {
    relationships: {
      field_imagemargin_img_: {
        uri: { url: image },
      },
    },
  } = data

  return (
    <section className={`${styles.blockImageMargin} ${bgColor}`}>
      <img src={`https://drupal.smartempo.com${image}`} />
    </section>
  )
}

export default BlockImageMargin
