import React from "react"
import loader from "./loader.gif"

const validateEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
const removeSubmit = elem => elem.type !== "submit"
const cleanField = elem => {
  elem.classList.remove("field-error")
  elem.classList.add("field-reset")
  return elem
}

const validateField = elem => {
  if (elem.type === "email") {
    return !validateEmail(elem.value) ? elem.classList.add("field-error") : elem
  } else {
    return elem.value === "" ? elem.classList.add("field-error") : elem
  }
}

class BlockContactForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
  }

  componentDidMount() {
    const aScript = document.createElement("script")
    aScript.type = "text/javascript"
    aScript.src = "https://app-sj24.marketo.com/js/forms2/js/forms2.min.js"

    document.head.appendChild(aScript)
    aScript.onload = function() {
      window.MktoForms2.loadForm(
        "https://app-sj24.marketo.com",
        "085-TAA-111",
        1061
      )
    }
  }

  validateForm = e => {
    e.preventDefault()
    Array.from(e.target.querySelectorAll("input"))
      .filter(removeSubmit)
      .map(cleanField)
      .map(validateField)

    // If all fields are valid, send form!
    if (!document.querySelectorAll(".field-error").length) {
      this.submitForm()
    }
  }

  submitForm = () => {
    document.getElementById("theForm").style.opacity = "0"

    setTimeout(() => {
      document.getElementById("loader").style.display = "flex"
      document.getElementById("loader").style.opacity = "1"
    }, 200)

    const myForm = window.MktoForms2.allForms()[0]
    myForm.addHiddenFields({
      Email: this.state.email,
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      Company: this.state.company,
      howCanWeHelp: this.state.email,
    })
    myForm.submit()
    myForm.onSuccess(function(values, followUpUrl) {
      document.getElementById("loader").style.opacity = "0"
      setTimeout(() => {
        document.getElementById("loader").style.display = "none"
      }, 200)

      setTimeout(() => {
        document.getElementById("thankYou").style.display = "flex"
        document.getElementById("thankYou").style.opacity = "1"
      }, 400)

      return false
    })
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value })
  }

  render() {
    return (
      <div className="contact__form">
        <div id="contactFormContent" className="contact__form__content">
          <div id="loader" className="loader">
            <img src={loader} />
          </div>
          <div id="thankYou" className="thankYou">
            <h3>Thank you</h3>
            <p>We will contact you shortly</p>
          </div>
          <div id="theForm" className="theForm">
            <form id="mktoForm_1061" style={{ display: "none" }} />
            <form
              id="contactForm"
              noValidate
              onSubmit={e => this.validateForm(e)}
            >
              <label htmlFor="firstName">
                <input
                  id="firstName"
                  placeholder="First Name"
                  type="text"
                  onChange={this.onChange}
                />
              </label>
              <label htmlFor="lastName">
                <input
                  id="lastName"
                  placeholder="Last Name"
                  type="text"
                  onChange={this.onChange}
                />
              </label>
              <label htmlFor="email">
                <input
                  id="email"
                  placeholder="Email Address"
                  type="email"
                  onChange={this.onChange}
                />
              </label>
              <label htmlFor="company">
                <input
                  id="company"
                  placeholder="Company"
                  type="text"
                  onChange={this.onChange}
                />
              </label>
              <label>
                <textarea
                  rows="5"
                  id="message"
                  placeholder="How can we help?"
                  onChange={this.onChange}
                />
              </label>
              <input type="submit" value="SEND" />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default BlockContactForm
