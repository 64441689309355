import { useStaticQuery, graphql } from "gatsby"

export const useHomePage = () => {
  const { allNodeHomePage } = useStaticQuery(
    graphql`
      query HomePage {
        allNodeHomePage {
          edges {
            node {
              field_textbig_text_ {
                processed
              }
              field_lefttitletext_first_text_
              field_lefttitletext_first_title_
              field_lefttitletextsmall_title_
              field_lefttitletextsmall_text_ {
                processed
              }
              field_band_text_ {
                processed
              }
              field_masonry_box_1_text_
              field_masonry_box_1_path_
              field_masonry_box_2_text_
              field_masonry_box_2_path_
              field_masonry_box_3_text_
              field_masonry_box_3_path_
              field_masonry_box_4_text_
              field_masonry_box_4_path_
              field_two_columns_col_1_title_
              field_two_columns_col_2_title_
              field_two_columns_col_1_text_ {
                processed
              }
              field_two_columns_col_2_text_ {
                processed
              }
              field_text_image_left_title_
              field_text_image_left_text_
              field_text_image_right_title_
              field_text_image_right_text_
              field_lefttitletext_second_title
              field_lefttitletext_second_text_
              field_lefttitletext_third_title_
              field_lefttitletext_third_text_
              relationships {
                field_full_width_img {
                  uri {
                    url
                  }
                }
                field_text_image_left_img_ {
                  uri {
                    url
                  }
                }
                field_text_image_right_img_ {
                  uri {
                    url
                  }
                }
                field_masonry_image_1 {
                  uri {
                    url
                  }
                }
                field_masonry_image_2 {
                  uri {
                    url
                  }
                }
                field_masonry_image_3 {
                  uri {
                    url
                  }
                }
                field_masonry_image_4 {
                  uri {
                    url
                  }
                }
                field_imagemargin_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeHomePage.edges[0].node
}
