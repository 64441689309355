import React from "react"
import styles from "./blockVideoMargin.module.css"

const BlockVideoMargin = ({ bgColor, data }) => {
  const {
    relationships: {
      field_imagemargin_img_: {
        uri: { url: image },
      },
    },
  } = data

  return (
    <section className={`${styles.blockImageMargin}`}>
      <iframe
        src="https://player.vimeo.com/video/332359976"
        width="840"
        height="560"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      />
    </section>
  )
}
export default BlockVideoMargin
